const variantName = 'footerV6'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      paddingX: [4, '', '', 4],
      paddingY: 10,
      backgroundColor: 'dark',
      color: '#bcbcbc',
      flexDirection: ['column', '', '', 'row'],
      flexWrap: ['wrap', '', '', 'nowrap'],
      justifyContent: ['', '', '', 'center'],
      alignItems: ['center', '', '', 'flex-start'],

      '.locationSwitcherV1': {
        display: 'none'
      },
      '.aboutColumn': {
        padding: ['', '', '', '2rem 2rem']
      },
      '.css-obsdbn': {
        marginRight: '0!important'
      },

      '.multiButtonContainer': {
        margin: '0rem 0rem',
        width: '100%',
        display: 'flex',
        justifyContent: ['center', '', '', 'flex-start'],
        a: {
          width: ['', '', '', '50%'],
          color: '#bcbcbc',
          margin: ['0.25rem', '', '', '0rem'],
          border: 'none',
          textAlign: ['', '', '', 'left'],
          padding: ['', '', '', '0.75rem 0rem'],
          fontWeight: 300,
          ':hover': {
            backgroundColor: 'transparent',
            opacity: '0.5'
          }
        }
      },
      '> div:nth-of-type(1)': {
        borderRight: ['', '', '', '1px solid'],
        padding: ['', '', '', '2rem 3rem 2rem 2rem']
      },
      '.contactDetails-container': {
        textAlign: ['center', '', '', 'left'],
        '.text': {
          margin: ['', '', '', '0.5rem 0rem'],
          fontSize: '1rem'
        }
      },
      '.socialContainer': {
        margin: ['1rem', '', '', '1rem 0rem'],
        svg: {
          marginRight: '0.5rem',
          width: '25px',
          height: '25px'
        }
      },
      '.gonationLogo': {
        justifyContent: ['center', '', 'flex-start'],
        alignItems: ['center', '', 'flex-start'],
        a: {
          justifyContent: ['center', '', 'flex-start'],
          alignItems: ['center', '', 'flex-start']
        }
      },
      'span.copyright': {
        textAlign: ['center', '', 'left']
      }
    },

    column: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-start'],
      width: ['', '', '', ''],
      padding: ['1rem 0rem', '', '1rem'],
      textAlign: 'left',
      marginBottom: ['', '', '', '1rem'],
      maxWidth: '350px'
    },

    // ?============================
    // ?=========  Texts  ==========
    // ?============================

    sectionHeading: {
      marginBottom: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid #bcbcbc',
      fontFamily: 'heading',
      fontWeight: '300',
      textTransform: 'uppercase'
    },

    aboutText: {
      color: '#bcbcbc',
      textAlign: ['center', '', '', 'left']
    },

    ctaButton: {
      variant: 'buttons.primary',
      marginTop: '1rem',
      padding: '0rem',
      color: 'primary',
      backgroundColor: 'transparent',
      border: 'none',
      ':hover': {
        color: 'white',
        textDecoration: 'underline'
      }
      // borderColor: '#bcbcbc',
      // borderRadius: '30px',
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: '150px',
      //   bg: 'white',
      p: 2,
      //   filter: 'brightness(0) invert(1)',
      marginBottom: '3rem'
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    quote: {
      margin: ['0rem auto', '', '', '0rem'],
      fontSize: '1rem',
      textAlign: ['center', '', '', 'left'],
      padding: '1rem 0rem',
      color: '#bcbcbc'
    }
  }
}
