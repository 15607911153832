export default {
  text: '#353535',
  primary: '#eba93b',
  secondary: '#447aa8',
  background: '#fff',
  backgroundSecondary: 'black',
  light: '#FFF',
  dark: '#353535',
  newBlue: '2e56a6'
}
