const variantName = 'menuV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: ['3rem 0.5rem', '3rem 1rem'],
      justifyContent: 'center',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    backToMenuBtn: {
      variant: 'buttons.secondary',
      // marginBottom: '2rem',
      borderRadius: '0px',
      textTransform: 'uppercase',
      position: 'fixed',
      bottom: ['0', '', '', '1rem'],
      right: '0',
      padding: ['1rem', '', '', '1rem 2rem'],
      backgroundColor: 'primary',
      color: 'white',
      zIndex: '1000'
      // backgroundColor: 'primary',
      // border: 'none',
      // margin: '0.5rem',
      // cursor: 'pointer',
      // justifyContent: 'center',
      // paddingBottom: 'calc(0.5rem - 1px)',
      // paddingLeft: '1rem',
      // paddingRight: '1rem',
      // paddingTop: 'calc(0.5rem - 1px)',
      // textAlign: 'center',
      // whiteSpace: 'nowrap',
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageFill: {
      paddingBottom: '100%' // * reuseable to make images square
    },

    // ?================================
    // ?===========  TABS  =============
    // ?================================

    tabsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      marginBottom: '3rem'
    },
    sectionTab: {
      // variant: 'buttons.secondary',
      padding: '1rem 0.5rem',
      // opacity: '0.7',
      flexGrow: '1',
      flex: ['', '', '', '', '1'],
      // border: 'solid 1px',
      border: 'none',
      textAlign: 'center',
      cursor: 'pointer',
      textTransform: 'uppercase',
      // border: 'solid 2px',
      color: 'dark',
      borderBottom: 'solid 1px',
      borderColor: 'white',
      whiteSpace: 'nowrap',
      margin: '0.25rem',
      transition: 'all ease-in-out 0.6s',
      letterSpacing: '0px',
      ':hover': {
        borderBottom: 'solid 1px',
        borderColor: 'primary'
      }
    },
    sectionTabActive: {
      variant: `${variantName}.sectionTab`,
      borderBottom: 'solid 1px !important',
      // borderColor: 'primary',
      color: 'black'
      // opacity: '1',
      // backgroundColor: 'primary',
    },

    // ?================================
    // ?=========  ALL IN  =============
    // ?================================

    allInContainerWrapper: {
      // border: 'solid 1px',
      // borderTop: 'none',
      maxWidth: '1200px',
      margin: '0 auto'
    },
    allInContainer: {},

    menuContainer: { paddingBottom: '2rem' },

    // ?================================
    // ?=========  CELLS  ==============
    // ?================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },

    sectionCell: {
      cursor: 'pointer',
      width: [
        '100%',
        'calc(50% - (0.5rem * 2))',
        'calc(33.333% - (0.5rem * 2))',
        'calc(33% - (0.5rem * 2))'
        // 'calc(25% - (0.5rem * 2))',
      ],
      backgroundColor: 'secondary',
      // borderRadius: '10px',
      margin: ['1rem 0', '0.5rem']
    },

    menuCell: {
      flexWrap: 'wrap',
      // border: 'solid 1px black',
      // borderRadius: '10px',
      height: '100%',
      backgroundColor: 'background',
      position: 'relative'
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset'
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
      // borderRadius: '10px 10px 0 0',
    },
    cellImageLogo: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
      // borderRadius: '10px 10px 0 0',
    },
    cellName: {
      // margin: '1rem',
      fontSize: '1.1rem',
      fontWeight: '300',
      textTransform: 'uppercase',
      position: 'absolute',
      top: '50%',
      left: '50%',
      textAlign: 'center',
      width: 'calc(100% - 2rem)',
      backgroundColor: 'rgb(12 12 12 / 82%)',
      padding: '2rem 2rem',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'primary',
      color: 'primary',
      transform: 'translate(-50%, -50%)'
    },

    // ?================================
    // ?========  Menu Sections ========
    // ?================================

    menuSectionTitle: {
      fontSize: ['1.5rem', '', '2rem'],
      textAlign: 'center',
      padding: ['0.5rem', '', '1rem'],
      borderBottom: '1px solid',
      borderColor: 'lightgrey',
      width: '100%',
      margin: '0rem 0rem 1.5rem',
      fontWeight: '300',
      textTransform: 'uppercase',
      textAlign: 'left',
      color: '#2e56a6',
      fontFamily: 'Caviar Dreams Bold'
    },
    menuSectionDescription: {
      textAlign: 'left',
      fontSize: '1.1rem',
      marginBottom: '1.25rem',
      padding: ['0.5rem', '', '1rem'],
      opacity: '0.7'
    },
    menuItemsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 0
    },

    // ?================================
    // ?========  Menu Items ===========
    // ?================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      padding: ['0 0.5rem 0rem 0rem', '', '0 1rem 0rem'],
      margin: ['0rem 0.5rem 0.5rem', '', '0.5rem 0.5rem 0.5rem 0rem'],
      width: ['100%', '', 'calc(50% - (0.5rem * 2))']
    },
    menuItemContainerImgActive: {
      variant: `${variantName}.menuItemContainer`
    },
    menuItemInnerContainer: {
      marginBottom: '1rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch'
    },

    // ?================================
    // ?======  Menu Item Image  =======
    // ?================================

    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['30%', '150px'],
      maxHeight: ['150px']
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      padding: '0 0.75rem 0 0',
      cursor: 'pointer'
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none'
    },

    // ?================================
    // ?=====  Menu Item Content  ======
    // ?================================

    menuItemContentContainer: {
      height: '100%',
      flexDirection: 'column',
      flexGrow: 2,
      // flexBasis: 2,
      width: '100%',
      position: 'relative'
    },
    menuItemName: {
      fontSize: ['1rem', '1.1rem', '', '1.2rem'],
      fontWeight: 'bolder',
      fontFamily: 'Caviar Dreams Bold',
      color: '#000',
      margin: '0px',
      paddingRight: '8px',
      fontWeight: '400',
      textTransform: 'uppercase',
      lineHeight: '1.5'
    },
    menuItemDescription: {
      fontSize: '1.1rem',
      whiteSpace: 'break-spaces',
      width: '90%',
      color: 'gray',
      fontFamily: 'Champagne',
      textTransform: 'lowercase'
    },

    // ?================================
    // ?======  Menu Items Price =======
    // ?================================

    menuPriceContainer: {
      // flexGrow: '1',
      justifyContent: 'flex-end',
      fontWeight: '400'
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right',
      fontSize: ['1rem', '1.1rem', '', '1.2rem'],
      lineHeight: '1.5'
    },

    variantsContainer: {},

    labelTitle: {
      display: 'flex'
    },
    variantContainer: {
      marginBottom: '8px',
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end'
    },

    variantContainerFloatTopRight: {
      position: 'absolute',
      right: '0rem',
      top: '0rem'
    },

    menuItemPriceLabel: {
      margin: '0px',
      marginRight: 'auto'
    },
    menuItemPriceVariants: {},

    // ?================================
    // ?======= Section Dropdown =======
    // ?================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative',
      display: 'none !important'
    },

    menuDropdownBtn: {
      background: 'primary',
      border: '2px solid',
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '1.25rem',
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '0.75rem 1.75rem'
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      boxShadow: '2px 2px 8px lightgrey',
      borderRadius: '10px',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      margin: '0 2.5%',
      display: 'flex',
      justifyContent: 'center',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        borderRadius: '4px',
        margin: 'auto',
        justifyContent: 'center'
      }
    },

    dropdownSection: {
      width: ['calc(50% - (0.5rem * 2))', 'calc(33.33% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      span: {
        '&:hover': {
          textDcoration: 'underline'
        }
      }
    }
  }
}
