export default {
  heading: 'Caviar Dreams, san-serif',
  body: 'Caviar Dreams, san-serif',
  monospace: 'Menlo, monospace',
  display: 'Caviar Dreams, serif',
  googleFonts: ['Lato:100,200,300,400,500,600,700,800,900', 'Playfair Display:400,500,600,700'],
  customFamilies: ['Caviar Dreams', 'Caviar Dreams Bold', 'Champagne'],
  customUrls: [
    'https://www.gonation.biz/fonts/caviar-dreams/caviar-dreams.css',
    'https://www.gonation.biz/fonts/caviar-dreams-bold/caviar-dreams-bold.css',
    'https://www.gonation.biz/fonts/champagne/champagne.css'
  ]
}
